<template>
  <div>
    <Spinner v-if="!cmsContentPending" class="mt-5 mb-5" size="medium" line-fg-color="#1f9979" />
    <div v-else class="histories">
      <!-- primary section -->
      <section class="primary">
        <b-container>
          <b-row>
            <b-col cols="12" md="7">
              <h1 class="primary__title" v-html="$prismic.asHtml(this.primary_title)"></h1>
              <p class="main-text main-text--semi" v-html="$prismic.asHtml(this.primary_text_1)"></p>
            </b-col>
            <b-col class="text-center" cols="12" md="4" offset-md="1">
              <img v-lazy="this.primary_image.url" class="primary__image img-fluid" :alt="this.primary_image.alt" />
            </b-col>
          </b-row>
        </b-container>
        <b-container class="mt-5">
          <b-row>
            <b-col cols="12" md="7">
              <p v-html="$prismic.asHtml(this.primary_text_2)" class="main-text"></p>
            </b-col>
            <b-col class="text-center" cols="12" md="4" offset-md="1">
              <p v-html="$prismic.asHtml(this.primary_text_3)" class="main-text primary__text--bold"></p>
              <a :href="this.primary_button_url" class="btn main-button primary__button">{{ $prismic.richTextAsPlain(this.primary_button_text) }}</a>
            </b-col>
          </b-row>
        </b-container>
      </section>
      <!-- benefits section -->
      <section class="benefits">
        <b-container>
          <b-row>
            <b-col cols="12">
              <h2 v-html="$prismic.asHtml(this.benefits_title)" class="benefits__subtitle"></h2>
              <p v-html="$prismic.asHtml(this.benefits_text)" class="main-text"></p>
            </b-col>
          </b-row>
          <div v-if="this.benefits_items.length > 0" class="benefits__box">
            <div v-for="(item, index) in this.benefits_items" :key="`${index}`" class="benefits__element">
              <img v-lazy="item.benefit_icon.url" class="benefits__icon img-fluid" :alt="item.benefit_icon.alt" />
              <h3 v-html="$prismic.asHtml(item.benefit_subtitle)" class="benefits__title"></h3>
              <p v-html="$prismic.asHtml(item.benefit_description)" class="benefits__text"></p>
            </div>
          </div>
        </b-container>
      </section>
      <!-- info section -->
      <section v-if="$prismic.richTextAsPlain(this.info_title)" class="info">
        <b-container>
          <b-row>
            <b-col cols="12">
              <h2 class="info__subtitle" v-html="$prismic.asHtml(this.info_title)"></h2>
              <div class="main-text" v-html="$prismic.asHtml(this.info_text)"></div>
            </b-col>
          </b-row>
        </b-container>
      </section>
      <!-- aside section -->
      <AsideComponent />
    </div>
  </div>
</template>

<script>
import Spinner from 'vue-simple-spinner'
export default {
  name: 'AboutPage',
  components: {
    Spinner,
    AsideComponent: () => import('@/components/AsideComponent/AsideComponent.vue')
  },
  data: () => {
    return {
      primary_title: null,
      primary_text_1: null,
      primary_text_2: null,
      primary_text_3: null,
      primary_button_text: null,
      primary_button_url: null,
      primary_image: null,
      benefits_title: null,
      benefits_text: null,
      benefits_items: [],
      info_title: null,
      info_text: null,
      cmsContentPending: false
    }
  },
  methods: {
    async getHouseHistoriesPageContent () {
      await this.$prismic.client.getSingle('househistoriespage')
        .then((document) => {
          // primary section
          this.primary_title = document.data.primary_title
          this.primary_text_1 = document.data.primary_text_1
          this.primary_text_2 = document.data.primary_text_2
          this.primary_text_3 = document.data.primary_text_3
          this.primary_button_text = document.data.primary_button_text
          this.primary_button_url = document.data.primary_button_url.url
          this.primary_image = document.data.primary_image
          // benefits section
          this.benefits_title = document.data.benefits_title
          this.benefits_text = document.data.benefits_text
          this.benefits_items = document.data.benefits_items
          // info section
          this.info_title = document.data.info_title
          this.info_text = document.data.info_text
          // meta title
          window.document.querySelector('head title').textContent = this.$prismic.richTextAsPlain(document.data.meta_title)
          this.cmsContentPending = true
        })
    }
  },
  created () {
    this.getHouseHistoriesPageContent()
  }
}
</script>

<style lang="scss">
  @import "./HouseHistoriesPage.scss";
</style>
